<div class="apply-confidentiality">
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="confidentialityInput"
    optionLabel="name"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  <div class="expiration-date">
    <p-calendar
      [minDate]="minDate"
      [maxDate]="maximumDate"
      [(ngModel)]="dateInput"
      dateFormat="dd / mm / yy"
      [readonlyInput]="true"
      inputId="expiration_date"
      placeholder="Expiration date (optional)"
    />
  </div>
  <p-button
    label="Approve & Continue verification"
    [disabled]="!confidentialityInput.name"
    (onClick)="approveDocument(confidentialityInput.name, dateInput)"
  />
</div>
