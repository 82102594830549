@if (loading) {
  <div class="skeleton-tags">
    <p-skeleton height="40px" class="skeleton-tags" styleClass="mb-2" />
  </div>
} @else {
  <div class="tags-container">
    <span class="span-header">Properties</span>
    <div class="tags-list {{ displayAllProperties ? 'expanded' : '' }}" #tagsList>
      @if (confidentiality) {
        <p-tag class="tag" icon="pi pi-lock" [value]="confidentiality" />
      }
      @for (tag of tags; track tag) {
        @if (!tag?.ignored) {
          @if (tag?.display_as_suggested) {
            <app-suggested-tag
              [tag]="tag"
              (ignoreSuggestedTag)="ignoreSuggestedTag($event)"
            />
          } @else {
            <p-tag class="tag" icon="pi pi-tag" [value]="tag.tag?.label" />
          }
        }
      }
      @if (hasRights) {
        @for (customProperty of customProperties; track customProperty) {
          @for (option of customProperty.selected_options; track option) {
            <p-tag class="tag" [value]="option.name" />
          }
          @for (option of customProperty.selected_people; track option) {
            <p-tag class="tag" value="{{customProperty.label_field.name}}: {{option.fullname}}" pTooltip="{{customProperty.label_field.name}}: {{option.fullname}}"/>
          }
        }
      }

      @if (hasNoProperty()) {
        <div class="no-property">
          <i class="pi pi-tags"></i>
          <span class="no-property-text">There's no property yet.</span>
        </div>
      }
    </div>
    <div class="tags-actions">
      @if (isTagViewExpandable()) {
        <div class="text-icon-button" (click)="displayAllProperties = !displayAllProperties">
          @if (displayAllProperties) {
            <i class="pi pi-eye-slash smaller-icon"></i>
            <span>Hide some of the properties</span>
          } @else {
            <i class="pi pi-eye smaller-icon"></i>
            <span>See all properties</span>
          }
        </div>
      }
      @if (!locked && hasRights) {
        <div
          class="text-icon-button "
          (click)="editPropertiesCallback.emit(DialogState.PROPERTIES)"
        >
          <i class="pi pi-pencil smaller-icon"></i>
          <span>Edit properties</span>
        </div>
      }
    </div>
  </div>
}
