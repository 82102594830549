import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Status } from 'app/pages/side-panel/side-panel.types';
import { FunctionEmitter } from 'app/types';
import { SidePanelComponent } from '../side-panel.component';

@Component({
  selector: 'side-panel-status-screens',
  templateUrl: './side-panel-status-screens.component.html',
  styleUrl: './side-panel-status-screens.component.scss',
})
export class SidePanelStatusScreensComponent {
  @Input() sidePanelStatus: string = '';
  @Output() onRefresh: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Output() onSensitivity: EventEmitter<FunctionEmitter> = new EventEmitter();

  protected sidePanelComponent = inject(SidePanelComponent);

  readonly SidePanelStatus = Status;
}
