<div class="contributors-container">
  <span class="span-header">Creator & Contributor(s)</span>
  <div class="contributions-container">
    @if (contributorHasData(creator)) {
      <p-tag
        class="people-tag"
        icon="pi pi-user"
        value="{{ extractNameFromPeople(creator) }}"
      />
    }
    @for (contributor of contributors; track contributor) {
      @if (contributorHasData(contributor) && canDisplayMoreContributors($index)) {
        <p-tag
          class="people-tag"
          icon="pi pi-users"
          value="{{ extractNameFromPeople(contributor) }}"
        />
      }
    }
  </div>
  @if (contributors && contributors.length > MAX_CONTRIBUTORS_DISPLAYED) {
    <div class="text-icon-button" (click)="displayAllContributors = !displayAllContributors">
      @if (displayAllContributors) {
        <i class="pi pi-eye-slash smaller-icon"></i>
      } @else {
        <i class="pi pi-eye smaller-icon"></i>
      }
      <span>{{ (displayAllContributors) ? "Hide some of the contributors" : "See all contributors" }}</span>
    </div>
  }
</div>
