import { Injectable } from '@angular/core';
import { People, User } from 'app/types';
import { map, Observable } from 'rxjs';
import { TrinityService } from 'app/services/trinity/trinity.service';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  /** Cached peoples. */
  private static peoples$: { [email: string]: Observable<People> } = {};

  private readonly endpoint: string = '/people';

  constructor(private trinity: TrinityService) {}

  getPeople(email: string): Observable<People> {
    const people = PeopleService.peoples$[email];

    if (people) {
      return people;
    }

    const observable = this.trinity.get<People>(
      `${this.endpoint}/${email}`,
      {
        authorized: true,
      },
    );

    PeopleService.peoples$[email] = observable;

    return observable;
  }

  list(
    query: string | undefined,
  ): Observable<{ users: People[]; groups: People[] }> {
    return this.trinity.get<{ users: People[]; groups: People[] }>(
      `${this.endpoint}/list`,
      {
        authorized: true,
        ...(query && { params: { query } }),
      },
    );
  }

   searchEntities(query: string) {
      return this.trinity
        .get<{ users: User[]; groups: { email: string }[] }>(
          '/people/list',
          {
            authorized: true,
            params: { query },
          },
        ).pipe(
          map((res) => res.users)
        )
    }
}
