import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import {
  DialogState,
  LikeButtonData,
} from 'app/pages/side-panel/side-panel.types';
import { Rating, User } from 'app/types';

@Component({
  selector: 'side-panel-footer',
  templateUrl: './side-panel-footer.component.html',
  styleUrl: './side-panel-footer.component.scss',
})
export class SidePanelFooterComponent {
  @Input() splitItems: MenuItem[] = [];
  @Input() likeButtonData?: LikeButtonData = undefined;
  @Input() loading: boolean = false;
  @Output() addRatingEvent = new EventEmitter();

  readonly DialogState = DialogState;

  ratedByTheUser: boolean = false;
  mainButtonLabel: string = '';
  mainButtonFunction: () => void = () => {};

  ngOnChanges() {
    if (this.mainButtonLabel !== this.splitItems[0].label) {
      this.mainButtonFunction = this.splitItems[0].command as () => void;
      this.mainButtonLabel = this.splitItems[0].label || '';
      this.splitItems = this.splitItems.slice(1);
    }
  }
}
