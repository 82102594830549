import { Component, Input, OnInit } from '@angular/core';
import { Permission } from 'app/types';

@Component({
  selector: 'app-custom-properties-list-people',
  templateUrl: './people.component.html',
  styleUrl: './people.component.scss',
})
export class PeopleComponent implements OnInit {
  @Input() permission?: Permission;

  truncatedListOfPeople!: string;
  fullListOfPeople?: string;

  ngOnInit() {
    if (this.permission?.entries.length) {
      this.truncatedListOfPeople = this.permission?.entries[0]?.value;
    }

    this.fullListOfPeople = this.permission?.entries
      .map((assoc) => assoc.value)
      .join(', ');
  }
}
