<div class="apply-properties">
  <div class="input-container">
    <span class="input-title">Tags:</span>
    <p-autoComplete
      [suggestions]="searchedTags"
      optionLabel="label"
      [multiple]="true"
      (completeMethod)="searchTags($event)"
      [(ngModel)]="tagsInput"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      placeholder="Select tags"
    >
      <ng-template let-tag pTemplate="item">
        @if (tag.id !== -1) {
          <div class="tag-selector">
            <i class="pi pi-tags"></i>
            <span>{{ tag.label }}</span>
          </div>
        } @else {
          <div class="tag-selector blue">
            <i class="pi pi-plus-circle"></i>
            <span>{{ tag.label }}</span>
          </div>
        }
      </ng-template>
    </p-autoComplete>
  </div>
  <div class="input-container">
    <span class="input-title">Confidentiality:</span>
    <p-dropdown
      [options]="confidentialityOptions"
      [(ngModel)]="confidentialitiesInput"
      placeholder="Confidentiality"
      class="dropdown-width"
    />
  </div>
  @if (hasRight) {
    @for (customLabel of customLabels; track customLabel) {
      @if (customLabel.options?.length || customLabel.type === 'PERSON') {
        @if (customLabel.type === 'PERSON') {
          @if (customLabel.allowMultiSelect) {
            <div class="input-container">
              <span class="input-title">{{ customLabel.label_field.name }}:</span>
              <p-autoComplete
                #autocomplete
                [(ngModel)]="selectedCustomLabelsPeople[$index]"
                [suggestions]="searchedPeople"
                (completeMethod)="searchPeople($event)"
                [placeholder]="customLabel.label_field.name"
                [multiple]="true"
                field="fullname"
                class="autocomplete-people"
              >
                <ng-template let-people pTemplate="item">
                  <div
                    class="search-result-inline"
                  >
                  <p-avatar
                    image="{{
                      people.photo_uri ?? default_avatar_uri
                    }}"
                      styleClass="mr-2"
                      size="normal"
                      shape="circle"
                      class="result-icon"
                    ></p-avatar>
                    <div>
                      {{
                        people.fullname ?? people.email
                      }}
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          }
          @else {
            <div class="input-container">
              <span class="input-title">{{ customLabel.label_field.name }}:</span>
              <p-autoComplete
                #autocomplete
                [(ngModel)]="selectedCustomLabelsPeople[$index][0]"
                [suggestions]="searchedPeople"
                (completeMethod)="searchPeople($event)"
                (onClear)="selectedCustomLabelsPeople[$index] = []"
                [placeholder]="customLabel.label_field.name"
                [showClear]="true"
                field="fullname"
                class="autocomplete-people"
              >
                <ng-template let-people pTemplate="item">
                  <div
                    class="search-result-inline"
                  >
                  <p-avatar
                    image="{{
                      people.photo_uri ?? default_avatar_uri
                    }}"
                      styleClass="mr-2"
                      size="normal"
                      shape="circle"
                      class="result-icon"
                    ></p-avatar>
                    <div>
                      {{
                        people.fullname ?? people.email
                      }}
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          }
        }
        @else {
          @if (customLabel.allowMultiSelect) {
            <div class="input-container">
              <span class="input-title">{{ customLabel.label_field.name }}:</span>
              <p-multiSelect
                [options]="customLabel.options"
                [(ngModel)]="selectedCustomLabelsOptions[$index]"
                optionLabel="name"
                [filter]="true"
                [maxSelectedLabels]="1"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
                [placeholder]="customLabel.label_field.name"
              >
              <ng-template let-custom pTemplate="item">
                <div class="tag-selector">
                  <i class="pi pi-tags"></i>
                  <span>{{ custom?.name }}</span>
                </div>
              </ng-template>
              </p-multiSelect>
            </div>
          } @else {
            <div class="input-container">
              <span class="input-title">{{ customLabel.label_field.name }}:</span>
              <p-dropdown
                [options]="customLabel.options"
                [filter]="true"
                optionLabel="name"
                [(ngModel)]="selectedCustomLabelsOptions[$index][0]"
                [placeholder]="customLabel.label_field.name"
                [showClear]="true"
                class="dropdown-width"
              >
                <ng-template let-custom pTemplate="item">
                  <div class="tag-selector">
                    <i class="pi pi-tags"></i>
                    <span>{{ custom?.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          }
        }
      }
    }
  }
  <diV class="properties-buttons">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="cancelPropertiesDialog()"
    />
    <p-button
      label="Save changes"
      (mousedown)="
        savePropertiesDialog({
          tags: tagsInput,
          confidentiality: confidentialitiesInput,
          documentType: documentTypeInput,
          language: languageInput,
          customPropertiesOptions: getSelectedCustomLabels(),
          customPropertiesPeople: getSelectedCustomPeople(),
        })
      "
    />
  </diV>
</div>
