import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { CreateCustomPropertyComponent } from '../create.component';

export const unsavedChangesGuard: CanDeactivateFn<
  CreateCustomPropertyComponent
> = (component) => {
  const confirmationService = inject(ConfirmationService);

  if (!component.customLabelForm?.dirty) {
    return true;
  }

  const subject = new Subject<boolean>();

  confirmationService.confirm({
    header: 'Leave without saving changes?',
    message: 'Are you sure you want to leave? All your changes will be lost. ',
    acceptLabel: 'No, go back to my changes',
    acceptButtonStyleClass: 'p-button-contrast',
    acceptIcon: undefined,
    rejectLabel: 'Yes, leave without saving changes',
    rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
    rejectIcon: undefined,
    accept: () => {
      subject.next(false);
    },
    reject: () => {
      subject.next(true);
    },
  });

  return subject.asObservable();
};
