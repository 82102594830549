<div class="side-panel-footer">
  @if (loading) {
    <p-skeleton height="69px" class="skeleton-footer" styleClass="mb-2" />
  } @else {
    <!-- Like buttons -->
    @if (likeButtonData?.likesCount) {
      <p-button
        class="flex-like"
        icon="pi pi-thumbs-up"
        label="{{ likeButtonData?.likesCount }}"
        [outlined]="!likeButtonData?.likedByMe"
        severity="secondary"
        (click)="likeButtonData?.onClick()"
      />
    } @else {
      <p-button
        class="flex-like"
        icon="pi pi-thumbs-up"
        label="Like"
        [outlined]="!likeButtonData?.likedByMe"
        severity="secondary"
        (click)="likeButtonData?.onClick()"
      />
    }
    <!-- Split button actions -->
    <p-splitButton
      [label]="mainButtonLabel"
      [model]="splitItems"
      outlined
      (onClick)="mainButtonFunction()"
      severity="secondary"
    />
  }
</div>
