@if (loading) {
  <div class="skeleton-workflow-versions">
    <p-skeleton
      height="40px"
      class="skeleton-workflow-versions"
      styleClass="mb-2"
    />
  </div>
} @else if (workflowFileVersionHistory) {
  <div class="workflow-versions-container">
    <span class="span-header">Version history</span>
    <div class="add-workflow-versions-container">
      @if (isLatestVersion()) {
        <div class="workflow-versions-no-newer-file">
          <i class="pi pi-verified"></i>
          <span>There's no newer version of that file.</span>
        </div>
      }

      @if (
        isLatestVersion() && workflowFileVersionHistory.versions.length > 1
      ) {
        @for (version of workflowFileVersionHistory.versions; track version) {
          <div class="history-row">
            <div class="history-gap">
              <i class="pi pi-file-check"></i>
              <span
                class="ellipsis-name text-link"
                (mousedown)="openFileInDrive(version?.file?.id)"
              >{{ version?.file?.datamart?.name }}</span
              >
              @if (version.file.id === file?.id) {
                <p-tag class="new-version-tag" value="Current" />
              } @else {
                <p-tag class="verified"
                       value="V{{ version?.workflow_instance?.version_major }}.{{version?.workflow_instance?.version_minor}}" />
              }
            </div>
            <span>{{ version?.workflow_instance?.finished_at | date }}</span>
          </div>
        }
      }

      @if (!isLatestVersion()) {
        <div
          class="warning-draft"
          (mousedown)="
            openFileInDrive(workflowFileVersionHistory.latest_version.file.id)
          "
        >
          <i class="pi pi-exclamation-triangle"></i>
          <span>There's a newer version of this file</span>
          <i class="pi pi-external-link"></i>
        </div>
      }
    </div>
  </div>
}
