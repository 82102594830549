import { Component, effect, inject, Inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomWorkflowService } from 'app/services/custom-workflow/custom-workflow.service';
import { AngularComponentInputs, WorkflowTemplate } from 'app/types';
import { tap } from 'rxjs';

@Component({
  selector: 'app-custom-workflow',
  templateUrl: './custom-workflow.component.html',
  styleUrl: './custom-workflow.component.scss',
})
export class CustomWorkflowComponent {
  workflowSelectedOption: WorkflowTemplate | null = null;
  startWorkflow: Function = () => {};
  readonly loadingTemplates = signal(true);

  private service = inject(CustomWorkflowService);
  templates$ = this.service
    .getWorkflowTemplates(this.inputs.documentId)
    .pipe(tap(() => this.loadingTemplates.set(false)));
  protected $templates = toSignal(this.templates$);

  constructor(
    @Inject('INPUTS') private inputs: AngularComponentInputs,
    @Inject('OUTPUTS') public outputs: AngularComponentInputs,
  ) {
    this.startWorkflow = outputs.startWorkflow;
  }
}
