import { Injectable } from '@angular/core';
import { TrinityService } from '../trinity/trinity.service';
import { map } from 'rxjs/operators';
import {
  CustomLabelApi,
  CustomLabelInput,
  CustomLabelInputPermissions,
  CustomLabelType,
  Metadata,
} from 'app/types';

@Injectable({
  providedIn: 'root',
})
export class CustomLabelService {
  private readonly endpoint: string = '/custom-property';

  constructor(private trinityService: TrinityService) {}

  getCustomLabels() {
    return this.trinityService
      .get<{ customProperties: CustomLabelApi[] }>(this.endpoint, {
        authorized: true,
      })
      .pipe(
        map((response) =>
          response.customProperties.map((customLabel) => {
            return {
              id: customLabel.id,
              name: customLabel.labelField.name,
              type: customLabel.gsheetFileId
                ? CustomLabelType.OPTIONS_LIST
                : CustomLabelType.PERSON,
              gsheetUrl: customLabel.gsheetFileId
                ? `https://docs.google.com/spreadsheets/d/${customLabel.gsheetFileId}`
                : '',
              allowMultiSelect: this.isMultiSelect(
                customLabel.labelField.custom_metadata,
              ),
              permissions: customLabel.permission,
              label_field: customLabel.labelField,
              options: customLabel.labelField.options,
            };
          }),
        ),
      );
  }

  getCustomLabel(id: string) {
    return this.trinityService
      .get<{ customProperty: CustomLabelApi }>(`${this.endpoint}/${id}`, {
        authorized: true,
      })
      .pipe(
        map((response) => {
          return {
            id: response.customProperty.id,
            name: response.customProperty.labelField.name,
            type: response.customProperty.gsheetFileId
              ? CustomLabelType.OPTIONS_LIST
              : CustomLabelType.PERSON,
            gsheetUrl: response.customProperty.gsheetFileId
              ? `https://docs.google.com/spreadsheets/d/${response.customProperty.gsheetFileId}`
              : '',
            allowMultiSelect: this.isMultiSelect(
              response.customProperty.labelField.custom_metadata,
            ),
            permission: response.customProperty.permission,
          };
        }),
      );
  }

  createCustomLabel(data: CustomLabelInput | CustomLabelInputPermissions) {
    return this.trinityService.post<CustomLabelApi>(this.endpoint, {
      body: data,
      authorized: true,
    });
  }

  updateCustomLabel(
    id: string,
    data: CustomLabelInput | CustomLabelInputPermissions,
  ) {
    return this.trinityService.put<CustomLabelApi>(`${this.endpoint}/${id}`, {
      body: data,
      authorized: true,
    });
  }

  deleteCustomLabel(id: string) {
    return this.trinityService.delete<CustomLabelApi>(
      `${this.endpoint}/${id}`,
      {
        authorized: true,
      },
    );
  }

  private isMultiSelect(metadata: Metadata) {
    if ('selectionOptions' in metadata) {
      return metadata.selectionOptions.listOptions.maxEntries > 1;
    }

    return metadata.userOptions.listOptions.maxEntries > 1;
  }
}
