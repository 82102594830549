import { Component, EventEmitter, input, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FileLabel, LabelFieldOption, LabelFieldOptionType, People } from 'app/types';

interface LabelPolicy {
  /** Whether the label is displayed or not. */
  displayable: boolean;
  /** The icon associated to the label. */
  icon: string;
  /** Whether the icon is a PrimeNG icon or not. */
  isPrimeNgIcon: boolean;
  /** The value of the label. */
  value: string;
  /** The css class of the label. */
  class: string;
  /** The function to call when the label is clicked. */
  onClick: (event: Event, op: OverlayPanel) => void;
}

@Component({
  selector: 'side-panel-labels',
  templateUrl: './side-panel-labels.component.html',
  styleUrl: './side-panel-labels.component.scss',
})
export class SidePanelLabelsComponent {
  $labels = input<FileLabel[] | undefined>([], { alias: 'labels' });
  $loading = input<boolean>(false, { alias: 'loading' });
  $locked = input<boolean | undefined>(false, { alias: 'locked' });
  $canSeeFlagged = input<boolean>(false, { alias: 'canSeeFlagged' });
  @Output() unlockFileEvent = new EventEmitter();

  options: LabelFieldOption[] = [];

  readonly LabelsMap: Map<LabelFieldOptionType, LabelPolicy> = new Map<
    LabelFieldOptionType,
    LabelPolicy
  >([
    [
      LabelFieldOptionType.STATUS_VERIFIED,
      {
        displayable: true,
        icon: 'pi pi-verified',
        isPrimeNgIcon: true,
        value: 'Verified',
        class: 'verified-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.STATUS_HAS_BEEN_EDITED,
      {
        displayable: true,
        icon: 'assets/icons/shield-off.svg',
        isPrimeNgIcon: false,
        value: 'Previously Verified',
        class: 'lost-verified-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.STATUS_VERIFIED_EXPIRED,
      {
        displayable: true,
        icon: 'pi pi-clock',
        isPrimeNgIcon: true,
        value: 'Previously Verified',
        class: 'lost-verified-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.STATUS_VERIFICATION_EXPIRES_SOON,
      {
        displayable: true,
        icon: 'pi pi-verified',
        isPrimeNgIcon: true,
        value: 'Verified (Expiring)',
        class: 'expiring-verified-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.VERIFICATION_PROCESS_PENDING,
      {
        displayable: false,
        icon: 'pi pi-clock',
        isPrimeNgIcon: true,
        value: 'Pending',
        class: 'pending-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.VERIFICATION_PROCESS_REJECTED,
      {
        displayable: false,
        icon: 'pi pi-times',
        isPrimeNgIcon: true,
        value: 'Rejected',
        class: 'rejected-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.STATUS_VERIFICATION_OUTDATED,
      {
        displayable: true,
        icon: 'pi pi-verified',
        isPrimeNgIcon: true,
        value: 'Verified (Outdated)',
        class: 'lost-verified-tag',
        onClick: () => null,
      },
    ],
    [
      LabelFieldOptionType.NEEDS_UPDATE_FLAGGED,
      {
        displayable: true,
        icon: 'pi pi-flag',
        isPrimeNgIcon: true,
        value: 'Flagged',
        class: 'flagged-tag',
        onClick: (event: Event, op: OverlayPanel) => this.onTagClick(event, op),
      },
    ],
  ]);

  displayFlagged(option: LabelFieldOption) {
    if (option.type !== LabelFieldOptionType.NEEDS_UPDATE_FLAGGED) return true;
    return this.$canSeeFlagged() && option.type === LabelFieldOptionType.NEEDS_UPDATE_FLAGGED;
  }

  onTagClick(event: Event, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
  }

  formatDate(date?: Date): string {
    if (!date) return '';

    const [year, month, day] = date.toString().split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  }

  getFlaggedMessage() {
    const flaggedLabel = this.$labels()?.find((label) =>
      label.selected_options.some(
        (option) => option.type === LabelFieldOptionType.NEEDS_UPDATE_FLAGGED,
      ),
    );

    return `${flaggedLabel?.labeled_by?.primary_email} - ${this.formatDate(flaggedLabel?.updated_at)} - "${flaggedLabel?.reason}"`;
  }

  ngOnChanges() {
    this.options = [];
    
    this.$labels()?.forEach((label) => {
      this.options.push(...label.selected_options);
    });
  }
}
