import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrinityService } from 'app/services/trinity/trinity.service';
import { VerifiedVersion } from 'app/types/versioning.types';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  private readonly endpoint: string = '/verified-history';
  constructor(private trinityService: TrinityService) {}

  getHistory(fileId: string): Observable<VerifiedVersion> {
    return this.trinityService.get<VerifiedVersion>(
      `${this.endpoint}/${fileId}`,
      {
        authorized: true,
      },
    );
  }

  createDraft(fileId: string): Observable<VerifiedVersion> {
    return this.trinityService.post<VerifiedVersion>(
      `${this.endpoint}/draft/${fileId}`,
      {
        authorized: true,
      },
    );
  }

  attachFileToHistory(
    historyId: number,
    fileId: string,
  ): Observable<VerifiedVersion> {
    return this.trinityService.post<VerifiedVersion>(
      `${this.endpoint}/attach`,
      {
        body: {
          historyId: historyId,
          fileId: fileId,
        },
        authorized: true,
      },
    );
  }
}
