import { Component, computed, inject, input, output } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { CustomWorkflowService } from 'app/services/custom-workflow/custom-workflow.service';
import {
  WorkflowInstance,
  WorkflowState,
  WorkflowStep,
  WorkflowStepState,
  workflowUserAction,
} from 'app/types';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'side-panel-activity',
  templateUrl: './side-panel-activity.component.html',
  styleUrl: './side-panel-activity.component.scss',
})
export class SidePanelActivityComponent {
  customWorkflowService = inject(CustomWorkflowService);

  $templates = input(0, { alias: 'templates' });
  $fileId = input<string | undefined>('', { alias: 'fileId' });
  $loading = input(false, { alias: 'loading' });
  $workflowInstances = input([], {
    alias: 'workflowInstances',
    transform: (instances: WorkflowInstance[] | undefined) =>
      (instances ?? []).sort(
        (a, b) =>
          new Date(b.finished_at ?? b.updated_at).getTime() -
          new Date(a.finished_at ?? a.updated_at).getTime(),
      ),
  });

  startWorkflowEvent = output<void>();
  approveWorkflowStepEvent = output<number>();
  approveWorkflowStepWithConfidentiality = output<void>();
  rejectWorkflowEvent = output<number>();
  drivePreview = output<string>();

  readonly Workflow = WorkflowState;
  readonly workflowStatesSingleIconMap = {
    [WorkflowState.VALIDATED]: 'pi pi-verified',
    [WorkflowState.NONE]: '',
    [WorkflowState.WAITING]: 'pi pi-sync',
    [WorkflowState.REJECTED]: 'pi pi-times',
    [WorkflowStepState.CANCELED]: 'pi pi-ban',
  };
  readonly workflowStatesMap = {
    [WorkflowState.VALIDATED]: {
      icon: 'pi pi-check',
      value: '',
      class: 'workflow-approved',
    },
    [WorkflowState.NONE]: {
      icon: '',
      value: 'No workflow started',
      class: '',
    },
    [WorkflowState.WAITING]: {
      icon: 'pi pi-sync',
      value: 'Pending approval',
      class: 'workflow-pending',
    },
    [WorkflowState.REJECTED]: {
      icon: 'pi pi-times',
      value: 'Rejected',
      class: 'workflow-rejected',
    },
  };

  workflowStepStatesMap = {
    [WorkflowStepState.IN_PROGRESS]: {
      value: '',
      class: 'workflow-pending',
      icon: 'pi pi-sync',
    },
    [WorkflowStepState.PENDING]: {
      value: '',
      class: 'workflow-pending',
      icon: 'pi pi-clock',
    },
    [WorkflowStepState.COMPLETED]: {
      value: '',
      class: 'workflow-approved',
      icon: 'pi pi-check',
    },
    [WorkflowStepState.CANCELED]: {
      value: '',
      class: 'workflow-rejected',
      icon: 'pi pi-times',
    },
  };

  $runningStep = computed(() =>
    this.$workflowInstances()?.reduce(
      (acc, w) =>
        acc ?? w.steps.find((s) => s.state === WorkflowStepState.IN_PROGRESS),
      null! as WorkflowStep,
    ),
  );

  private runningStep$ = toObservable(this.$runningStep);
  private authorizations$ = this.runningStep$.pipe(
    map((step) => step?.id),
    switchMap((id) => this.customWorkflowService.canContinueWorkflowStep(id)),
  );
  $authorizations = toSignal(this.authorizations$);

  approveWorkflow(instance: WorkflowInstance, step: WorkflowStep) {
    const validations = instance.steps.filter(
      (step) => step.template.type === 'user_validation',
    );

    if (
      validations.at(-1) === step &&
      step.state === WorkflowStepState.IN_PROGRESS &&
      step.template.type === 'user_validation'
    )
      this.approveWorkflowStepWithConfidentiality.emit();
    else this.approveWorkflowStepEvent.emit(step.id);
  }

  getWorkflowInstanceFormatedVersion(instance: WorkflowInstance) {
    if (instance.version_minor === 0) return `V${instance.version_major}`
    return `V${instance.version_major}.${instance.version_minor}`
  }

  workflowInstanceHasARejectedUserAction(instance: WorkflowInstance) {
    return instance.steps.some((step) =>
      step.user_actions.some(action => action.type === 'REJECT_DOCUMENT_VERIFICATION')
    );
  }

  getTimeFromDate(date: string) {
    return date.split('T')[1].split('.')[0];
  }

  getStepActionUsers(userActions: workflowUserAction[]) {
    if (!userActions.length) return '';
    return `by ${userActions.map((action) => action.user.people.fullname).join(', ')}`;
  }

  getRequesterUser(instance: WorkflowInstance) {
    return `by ${instance.started_by.people.fullname}`;
  }

  isStepRejected(step: WorkflowStep) {
    return step.user_actions.some((action) => action.type === 'REJECT_DOCUMENT_VERIFICATION');
  }
}
