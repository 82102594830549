import { Component, input, output } from '@angular/core';
import { TagDatamart } from 'app/types';

@Component({
  selector: 'app-suggested-tag',
  templateUrl: './suggested-tag.component.html',
  styleUrl: './suggested-tag.component.scss',
})
export class SuggestedTagComponent {
  $tag = input<TagDatamart | undefined>(undefined, { alias: 'tag' });
  ignoreSuggestedTag = output<TagDatamart | undefined>();
}
