import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import {
  canUnverifyFileResponse,
  FileHistoryResponse,
  FileResponse,
  PopulatedDriveFileResponse,
  LabelType,
  canUnflagFileResponse,
} from 'app/types';
import { Observable } from 'rxjs';

interface SetTagsOptions {
  /** The ID of the file to set the tags for. */
  file_id: string;

  /** The tags to set (set to empty array to remove all tags). */
  ids: number[];
}

interface SetLabelsOptions {
  /** The ID of the file to set the labels for. */
  file_id: string;

  /** The labels to set (set to empty array to remove all labels). */
  labels: LabelType[];
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly endpoint: string = '/file';

  constructor(private trinityService: TrinityService) {}

  get(file_id: string): Observable<PopulatedDriveFileResponse> {
    return this.trinityService.get<PopulatedDriveFileResponse>(`/drive/file/${file_id}`, {
      authorized: true,
    });
  }

  unlockFile(file_id: string): Observable<FileResponse> {
    return this.trinityService.delete<FileResponse>(
      `${this.endpoint}/lock/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  getFileHistory(file_id: string): Observable<FileHistoryResponse> {
    return this.trinityService.get<FileHistoryResponse>(
      `${this.endpoint}/history/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  canUnflagFile(fileId: string): Observable<canUnflagFileResponse> {
    return this.trinityService.get<canUnflagFileResponse>(
      `${this.endpoint}/unflag/${fileId}/permissions`,
      {
        authorized: true,
      },
    );
  }

  canUnverifyFile(fileId: string): Observable<canUnverifyFileResponse> {
    return this.trinityService.get<canUnverifyFileResponse>(
      `${this.endpoint}/unverify/${fileId}/permissions`,
      {
        authorized: true,
      },
    );
  }

  unverifyFile(fileId: string) {
    return this.trinityService.put(`${this.endpoint}/unverify/${fileId}`, {
      authorized: true,
    });
  }

  syncFile(fileId: string) {
    return this.trinityService.get(`/drive${this.endpoint}/${fileId}/sync`, {
      authorized: true,
    });
  }
}
