@if (loading) {
  <p-skeleton height="70px" class="skeleton-rating" styleClass="mb-2" />
} @else {
  @if (display) {
  <div class="ratings-container">
    <span class="span-header">Ratings</span>
    <div class="add-rating-container">
        <i class="pi pi-star"></i>
        <span>You did not leave a rating yet.</span>
        <div
          class="text-icon-button"
          (mousedown)="addRatingEvent.emit(DialogState.RATING)"
        >
          <i class="pi pi-plus-circle"></i>
          <span>Add a rating</span>
        </div>
      </div>
  </div>
  }
}
