import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import { Observable } from 'rxjs';
import {
  BulkResponse,
  UpdateFilePropertiesOptions,
  UpdateFilesConfidentialityOptions,
  UpdateFilesTagsOptions,
} from 'app/types/bulk.types';
import { CustomLabelField } from 'app/types';

@Injectable({
  providedIn: 'root',
})
export class BulkService {
  constructor(private trinityService: TrinityService) {}

  private readonly endpointFiles: string = '/bulk/files';

  updateFilesProperties(
    filesProperties: UpdateFilePropertiesOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/properties`,
      {
        body: filesProperties,
        authorized: true,
      },
    );
  }

  updateFilesConfidentiality(
    filesConfidentiality: UpdateFilesConfidentialityOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/confidentiality`,
      {
        body: filesConfidentiality,
        authorized: true,
      },
    );
  }

  updateFileTags(
    filesTags: UpdateFilesTagsOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/tags`,
      {
        body: filesTags,
        authorized: true,
      },
    );
  }

  updateFilesDescription(
    ids: string[],
    description: string,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/description`,
      {
        body: {
          file_ids: ids,
          description: description,
        },
        authorized: true,
      },
    );
  }

  flagFiles(
    ids: string[],
    reason: string,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/flagged`,
      {
        body: {
          file_ids: ids,
          reason: reason,
        },
        authorized: true,
      },
    );
  }

  unflagFiles(ids: string[]): Observable<BulkResponse<File, string>> {
    return this.trinityService.delete<BulkResponse<File, string>>(
      `${this.endpointFiles}/flagged`,
      {
        body: {
          file_ids: ids,
        },
        authorized: true,
      },
    );
  }

  updateFilesCustomLabels(fileIds: string[], fields: CustomLabelField[], peopleFields: {
    field_id: string;
    people_emails: string[] | null;
  }[]): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/labels/custom`,
      {
        body: {
          file_ids: fileIds,
          fields: [...fields, ...peopleFields],
        },
        authorized: true,
      },
    );
  }
}
