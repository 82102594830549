@if (loading) {
  <div class="spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
} @else if (customLabels.length === 0 && !loading) {
  <p>
    There is no custom property created yet: click on the button “Create a
    custom property” above to create your first custom property.
  </p>
} @else {
  <div class="data">
    <p-table
      #dt1
      [value]="customLabels"
      dataKey="id"
      [globalFilterFields]="['name', 'type']"
    >
      <ng-template pTemplate="caption">
        <p-iconField iconPosition="left">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>
          <input
            pInputText
            type="text"
            #filterInput
            (input)="dt1.filterGlobal(filterInput.value, 'contains')"
            placeholder="Search a custom property"
          />
        </p-iconField>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">Name <p-sortIcon field="name" /></th>
          <th pSortableColumn="type">
            Property type <p-sortIcon field="type" />
          </th>
          <th>List of options</th>
          <th>User availability</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customProperty>
        <tr>
          <td>{{ customProperty.name }}</td>
          <td>{{ customProperty.type }}</td>
          <td>
            @if (customProperty.gsheetUrl) {
              <a href="{{ customProperty.gsheetUrl }}" target="_blank"
                >G-Sheet <i class="pi pi-link"></i
              ></a>
            }
          </td>
          <td>
            <app-custom-properties-list-people
              [permission]="customProperty.permissions"
            />
          </td>
          <td>
            <p-menu
              #lineMenu
              [model]="lineMenuItems[customProperty.id]"
              [popup]="true"
              appendTo="body"
              [id]="customProperty.id"
            />
            <p-button
              (onClick)="lineMenu.toggle($event)"
              icon="pi pi-ellipsis-v"
              [text]="true"
              [plain]="true"
            />
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No custom property found.</td>
        </tr>
      </ng-template>
    </p-table>

    <p class="data__infos">You can create up to 10 custom properties</p>
    <p-confirmDialog />
  </div>
}
