@let tag = $tag();
@if (tag) {
  <div class="container">
    <i class="pi pi-sparkles tag-icon"></i>
    <span class="tag-text">{{ tag.tag?.label }}</span>
    <i
      class="pi pi-times tag-icon clickable"
      (mousedown)="oplocked.toggle($event)"
    ></i>
  </div>
  <p-overlayPanel #oplocked>
    <div class="title">Remove this suggestion?</div>
    <div class="buttons-container">
      <p-button
        label="No"
        class="button button-no"
        size="small"
        variant="outlined"
        (onClick)="oplocked.hide()"
      />
      <p-button
        label="Yes"
        size="small"
        class="button"
        [raised]="true"
        severity="danger"
        (onClick)="oplocked.hide(); ignoreSuggestedTag.emit(tag)"
      />
    </div>
  </p-overlayPanel>
}
