import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetupOnboardingComponent } from './components/setup-onboarding/setup-onboarding.component';
import { authGuard } from './guards/auth.guard';
import { FolderContentComponent } from './pages/folder-content/folder-content.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { CreateCustomPropertyComponent } from './pages/settings/custom-properties/create/create.component';
import { unsavedChangesGuard } from './pages/settings/custom-properties/create/guard/unsaved-changes.guard';
import { CustomPropertiesComponent } from './pages/settings/custom-properties/custom-properties.component';
import { ListCustomPropertiesComponent } from './pages/settings/custom-properties/list/list.component';
import { InstallationComponent } from './pages/settings/installation/installation.component';
import { SettingsComponent } from './pages/settings/settings.component';
import {
  isDomainOperator,
  isGoogleAdmin,
} from './pages/settings/settings.guard';
import { SidePanelComponent } from './pages/side-panel/side-panel.component';
import { TodoComponent } from './pages/todo/todo.component';
import { VerifiedDocsComponent } from './pages/verified-docs/verified-docs.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'todo', component: TodoComponent, canActivate: [authGuard] },
  {
    path: 'folder',
    children: [
      {
        path: '**',
        component: FolderContentComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'verified',
    component: VerifiedDocsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'search',
    component: SearchResultsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'side-panel',
    component: SidePanelComponent,
    canActivate: [authGuard],
  },
  {
    path: 'settings',
    title: 'Overlayer - App settings',
    component: SettingsComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'unauthorized',
        loadComponent: () =>
          import('./pages/settings/unauthorized/unauthorized.component'),
      },
      {
        path: 'installation',
        canActivate: [isGoogleAdmin],
        title: 'Overlayer - App settings - Installation',
        component: InstallationComponent,
        data: {
          title: 'Installation settings',
          icon: 'pi-sliders-h',
        },
      },
      {
        path: 'custom-properties',
        canActivate: [isDomainOperator],
        title: 'Overlayer - App settings - Custom properties',
        component: CustomPropertiesComponent,
        data: {
          title: 'Custom properties',
          icon: 'pi-tags',
        },
        children: [
          {
            path: 'list',
            component: ListCustomPropertiesComponent,
          },
          {
            path: 'create',
            component: CreateCustomPropertyComponent,
            canDeactivate: [unsavedChangesGuard],
          },
          {
            path: 'edit/:id',
            component: CreateCustomPropertyComponent,
            canDeactivate: [unsavedChangesGuard],
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
        ],
      },
      {
        path: 'custom-workflows',
        canActivate: [isDomainOperator],
        title: 'Overlayer - App settings - Custom workflows',
        data: {
          title: 'Custom workflows',
          icon: 'pi-sitemap',
        },
        loadComponent: () =>
          import(
            './pages/settings/custom-workflows/custom-workflows.component'
          ),
        children: [
          {
            path: 'list',
            loadComponent: () =>
              import('./pages/settings/custom-workflows/list/list.component'),
          },
          {
            path: 'create',
            loadComponent: () =>
              import(
                './pages/settings/custom-workflows/create/create.component'
              ),
            canDeactivate: [unsavedChangesGuard],
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
        ],
      },
      {
        path: 'teams',
        canActivate: [isGoogleAdmin],
        loadComponent: () =>
          import('./pages/settings/teams-manager/teams-manager.component'),
        title: 'Overlayer - App settings - Teams',
        data: {
          title: 'Domain operators',
          icon: 'pi-users',
        },
        children: [
          {
            path: 'domain-operators',
            loadComponent: () =>
              import(
                './pages/settings/teams-manager/domain-operators/domain-operators.component'
              ),
          },
          { path: '', redirectTo: 'domain-operators', pathMatch: 'full' },
        ],
      },
      { path: '', redirectTo: 'installation', pathMatch: 'full' },
    ],
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'onboarding/start',
    component: SetupOnboardingComponent,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
