import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { Role, User } from 'app/types';

export { isDomainOperator, isGoogleAdmin };

const isGoogleAdmin: CanActivateFn = () => {
  const authorized = !!inject(LocalStorageService)
    .getItem<User>(USER_INFO_KEY)
    ?.roles?.includes(Role.GOOGLE_DOMAIN_ADMIN);

  if (!authorized)
    return inject(Router).createUrlTree(['settings', 'unauthorized']);

  return true;
};

const isDomainOperator: CanActivateFn = () => {
  const authorized = !!inject(LocalStorageService)
    .getItem<User>(USER_INFO_KEY)
    ?.roles?.includes(Role.DOMAIN_OPERATOR);

  if (!authorized)
    return inject(Router).createUrlTree(['settings', 'unauthorized']);

  return true;
};
