import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { MrSatanService } from 'app/services/mrsatan/mrsatan.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GsheetValueValidator implements AsyncValidator {
  constructor(private mrSatanService: MrSatanService) {}

  validate(
    control: AbstractControl,
    forceQuery?: boolean,
  ): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    if (forceQuery) {
      return this.mrSatanService.checkSheet(control.value).pipe(
        map((result) => {
          if (result.error) {
            return { error: result.error };
          }

          if (result.warnings && result.warnings.length > 0) {
            return { warnings: result.warnings };
          }

          return null;
        }),
        catchError((err) => {
          return of({ error: err.error.message });
        }),
      );
    } else {
      return of(null);
    }
  }
}
