import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { gsheetUrlValidator } from './directives/gsheet-url.directive';
import { GsheetValueValidator } from './directives/gsheet-value.directive';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { CustomLabelService } from 'app/services/custom-label/custom-label.service';
import { MrSatanService } from 'app/services/mrsatan/mrsatan.service';
import { PeopleService } from 'app/services/people/people.service';
import { People, CustomLabelType, PermissionEntryType } from 'app/types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-custom-properties-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
})
export class CreateCustomPropertyComponent implements OnInit {
  customLabelId: string | undefined;
  submitting: boolean = false;
  submitError: string | null = null;

  customLabelForm = this.formBuilder.group({
    name: ['', Validators.required],
    type: ['PERSON'],
    gsheetUrl: [
      '',
      {
        disabled: true,
        updateOn: 'change',
        validators: [gsheetUrlValidator()],
        asyncValidators: [
          this.gsheetValueValidator.validate.bind(this.gsheetValueValidator),
        ],
      },
    ],
    allowMultiSelect: [false, Validators.required],
    permissions: [
      [] as { email: string; fullname?: string; type?: PermissionEntryType }[],
    ],
  });

  activeStepIndex: number = 0;
  directorySuggestions: People[] = [];
  groups: People[] = [];
  loadingGsheet: boolean = false;
  gsheetLoadingComplete: boolean = false;
  isEditMode: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private gsheetValueValidator: GsheetValueValidator,
    private customLabelService: CustomLabelService,
    private mrsatanService: MrSatanService,
    private peopleService: PeopleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    // If we have customLabelId, it's an edition. Load the custom property data into the form
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.isEditMode = true;
        this.activeStepIndex = 1;
        this.customLabelId = params['id'];

        // Those fields are not editable
        this.customLabelForm.get('type')?.disable();
        this.customLabelForm.get('gsheetUrl')?.disable();

        this.customLabelService
          .getCustomLabel(this.customLabelId!)
          .subscribe((customProperty) => {
            this.customLabelForm.setValue({
              allowMultiSelect: customProperty.allowMultiSelect,
              gsheetUrl: customProperty.gsheetUrl,
              name: customProperty.name,
              type: customProperty.type,
              permissions:
                customProperty.permission?.map((permission) => {
                  return {
                    email: permission?.people?.email ?? '',
                    fullname: permission?.people?.fullname ?? permission?.value,
                    type: permission.type,
                  };
                }) ?? null,
            });
          });
      }
    });

    // Preload directory suggestions
    this.getDirectorySuggestions();

    // When we select the type 'person', we need to reset the gsheetUrl field
    this.customLabelForm.get('type')?.valueChanges.subscribe((value) => {
      if (value === 'person') {
        this.customLabelForm.get('gsheetUrl')?.setValue('');
      }
    });
  }

  verifyGsheet() {
    this.loadingGsheet = true;
    this.gsheetValueValidator
      .validate(this.customLabelForm.get('gsheetUrl')!, true)
      .subscribe({
        next: () => {},
        complete: () => {
          this.loadingGsheet = false;
          this.gsheetLoadingComplete = true;
        },
      });
  }

  onSubmit() {
    this.submitError = null;

    // If the form is invalid, mark all fields as dirty to show the errors
    // The form remains valid if the gsheetUrl has warnings only
    if (!this.isFormValidIgnoringGsheetWarnings()) {
      Object.keys(this.customLabelForm.controls).forEach((key) => {
        this.customLabelForm.get(key)?.markAsDirty();
      });
      this.submitError = 'Please fill in all required fields';
      return;
    }

    this.submitting = true;
    const { gsheetUrl, name, type, permissions, allowMultiSelect } =
      this.customLabelForm.getRawValue();

    const permission = permissions?.map((permission) => {
      return {
        value: permission.email,
        type: this.groups.find((group) => group.email === permission.email)
          ? PermissionEntryType.GROUP
          : PermissionEntryType.USER,
      };
    });
    const data = {
      id: this.customLabelId,
      name: name ?? '',
      type: type as CustomLabelType,
      gsheetId: gsheetUrl
        ? this.mrsatanService.extractSpreadsheetId(gsheetUrl)
        : null,
      allowMultiSelect: allowMultiSelect ?? false,
      permissions: permission ?? [],
    };

    if (data.id) {
      this.customLabelService.updateCustomLabel(data.id, data).subscribe({
        complete: () => {
          this.customLabelForm.reset();
          this.router.navigate(['../../list'], {
            relativeTo: this.activatedRoute,
          });
        },
        error: (err) => {
          this.submitError =
            err.error.message?.message ??
            'Error while updating the custom property';
          this.submitting = false;
        },
      });
    } else {
      this.customLabelService.createCustomLabel(data).subscribe({
        complete: () => {
          this.customLabelForm.reset();
          this.router.navigate(['../list'], {
            relativeTo: this.activatedRoute,
          });
        },
        error: (err) => {
          this.submitError =
            err.error.message?.message ??
            'Error while creating the custom property';
          this.submitting = false;
        },
      });
    }
  }

  isFinalStepDisabled(): boolean {
    if (
      this.customLabelForm.get('type')?.value === CustomLabelType.OPTIONS_LIST
    )
      return (
        (this.customLabelForm.controls.gsheetUrl.errors?.['error'] ||
          this.customLabelForm.get('gsheetUrl')?.value === '' ||
          !this.gsheetLoadingComplete) &&
        !this.isEditMode
      );
    else return this.customLabelForm.controls.gsheetUrl.errors?.['error'];
  }

  private isFormValidIgnoringGsheetWarnings(): boolean {
    const gsheetUrlControl = this.customLabelForm.get('gsheetUrl');
    if (this.customLabelForm.valid) {
      return true;
    }

    const allFieldsExceptGsheetAreValid = Object.keys(
      this.customLabelForm.controls,
    ).every((controlName) => {
      if (controlName === 'gsheetUrl') {
        return true;
      }

      return this.customLabelForm.get(controlName)?.valid;
    });

    if (
      allFieldsExceptGsheetAreValid &&
      gsheetUrlControl?.errors &&
      gsheetUrlControl.errors['warnings'] &&
      !gsheetUrlControl.errors['error']
    ) {
      return true;
    }

    return false;
  }

  goToPreviousStep(fromIndex: number) {
    this.activeStepIndex = Math.max(fromIndex - 1, 0);
  }

  goToNextStep(fromIndex: number) {
    this.activeStepIndex = Math.min(fromIndex + 1, 2);
  }

  getDirectorySuggestions(event?: AutoCompleteCompleteEvent) {
    this.peopleService.list(event?.query).subscribe((people) => {
      this.directorySuggestions = [...people.users, ...people.groups];
      this.groups = people.groups;
    });
  }

  protected readonly CustomLabelType = CustomLabelType;
}
