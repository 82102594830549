import { Component, Input } from '@angular/core';
import { File, WorkflowFileVersionHistory } from 'app/types';

@Component({
  selector: 'side-panel-workflow-versions',
  templateUrl: './side-panel-workflow-versions.component.html',
  styleUrl: './side-panel-workflow-versions.component.scss',
})
export class SidePanelWorkflowVersionsComponent {
  @Input() workflowFileVersionHistory?: WorkflowFileVersionHistory = undefined;
  @Input() file?: File = undefined;
  @Input() loading: boolean = false;

  isLatestVersion(): boolean {
    return (
      this.workflowFileVersionHistory?.latest_version?.file?.id ===
      this.file?.id
    );
  }

  openFileInDrive(fileId?: string) {
    window.open(`https://docs.google.com/open?id=${fileId}`, '_blank')?.focus();
  }
}
