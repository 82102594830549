import { Component, OnInit } from '@angular/core';
import { ToastService } from 'app/services/toast/toast.service';
import { SettingsContentType } from '../settings.types';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import {
  AuthorizedGoogleDrive,
  Client,
  Role,
  UpdateClientOptions,
  User,
} from 'app/types';
import { ClientService } from 'app/services/client/client.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';

@Component({
  selector: 'app-custom-shared-drives',
  templateUrl: './custom-shared-drives.component.html',
  styleUrl: './custom-shared-drives.component.scss',
})
export class CustomSharedDrivesComponent implements OnInit {
  readonly wording: SettingsContentType = {
    title: 'Overlayer shared drives custom installation',
    selectedLabel:
      'This option has been activated. Please select or unselect shared drives within the following list to add or remove Overlayer from them:',
    label:
      'This option is not activated which means that Overlayer is currently installed on all your shared drives.',
    name: 'custom-shared-drives',
    errors: {
      noRightsToast: {
        summary: 'File Permissions',
        detail: 'Failed to enable',
      },
    },
  };
  hasRights: boolean = false;
  userDrives: AuthorizedGoogleDrive[] = [];
  client?: Client = undefined;
  loadingDrives: boolean = false;
  displayConfirmationDialog: boolean = false;

  constructor(
    private toastService: ToastService,
    private gdrivesService: GdrivesService,
    private clientService: ClientService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.getClient();
    this.loadingDrives = true;
    this.getUserDrives();
    this.getUserRights();
  }

  getClient() {
    this.clientService.getClient().subscribe({
      next: ({ client }) => {
        this.client = client;
      },
    });
  }

  getUserRights() {
    const roles = this.localStorageService.getItem<User>(USER_INFO_KEY)?.roles;

    if (roles?.includes(Role.GOOGLE_DOMAIN_ADMIN)) {
      this.hasRights = true;
    }
  }

  resetPage() {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  updateClient(
    customInstall?: boolean,
    automaticallyWatchNewDrives?: boolean | Date,
    isConfidentialityEnabled?: boolean,
  ) {
    // Update the client confidentiality settings
    this.clientService
      .modifyClient({
        name: this?.client?.name,
        custom_drive_watch:
          customInstall ??
          this.client?.service_configuration?.custom_drive_watch,
        watch_all_new_drives_created_after_date: !automaticallyWatchNewDrives
          ? null
          : this.client?.service_configuration
                ?.watch_all_new_drives_created_after_date
            ? this.client?.service_configuration
                ?.watch_all_new_drives_created_after_date
            : new Date(),
        disabled_on_secret_confidentiality: isConfidentialityEnabled ?? false,
      })
      .subscribe({
        next: () => {
          this.toastService.success({
            summary: 'Success',
            detail: 'Your changes have been taken into account',
          });
        },
        error: () => {
          this.toastService.error({
            summary: 'Error',
            detail: 'Failed to update the client custom installation',
          });
        },
      });
  }

  watchAllDrives() {
    const drivesToWatch = this.userDrives
      .filter((drive) => !drive.is_watched)
      .map((drive) => drive.id);

    if (!drivesToWatch.length) {
      return;
    }
    this.gdrivesService.watchDrives(drivesToWatch).subscribe({
      next: ({ failed }) => {
        if (failed.length) {
          this.toastService.error({
            summary: 'Failed to Add Access',
            detail:
              'Unable to grant access to all shared drives. Please try again later.',
          });
          this.resetPage();
        } else {
          this.toastService.success({
            summary: 'Added Successfully',
            detail: 'Access successfully added to all shared drives.',
          });
        }
      },
      error: () => {
        this.toastService.error({
          summary: 'Failed to Add Access',
          detail:
            'Unable to grant access to all shared drives. Please try again later.',
        });
        this.resetPage();
      },
    });
  }

  updateWatchedDrives(updateClientOption: UpdateClientOptions) {
    const newDriveToWatch: string[] = [];
    const newDriveToUnwatch: string[] = [];

    this.userDrives.forEach((drive) => {
      if (
        drive.is_watched &&
        !updateClientOption.googleDrives?.find((d) => d.id === drive.id)
      ) {
        newDriveToUnwatch.push(drive.id);
      }
      if (
        !drive.is_watched &&
        updateClientOption.googleDrives?.find((d) => d.id === drive.id)
      ) {
        newDriveToWatch.push(drive.id);
      }
    });

    // Update the client drive list if required
    if (newDriveToUnwatch.length) {
      this.gdrivesService.unwatchDrives(newDriveToUnwatch).subscribe({
        next: ({ failed }) => {
          if (failed.length) {
            this.toastService.error({
              summary: 'Failed to Remove Access',
              detail:
                'Unable to remove access to the unselected shared drives. Please try again later.',
            });
          } else {
            this.toastService.success({
              summary: 'Removed Successfully',
              detail:
                'Access successfully removed from the unselected shared drives.',
            });
          }
          this.resetPage();
        },
        error: () => {
          this.toastService.error({
            summary: 'Failed to Remove Access',
            detail:
              'Unable to remove access to the unselected shared drives. Please try again later.',
          });
          this.resetPage();
        },
      });
    }

    if (newDriveToWatch.length) {
      this.gdrivesService.watchDrives(newDriveToWatch).subscribe({
        next: ({ failed }) => {
          if (failed.length) {
            this.toastService.error({
              summary: 'Failed to Add Access',
              detail:
                'Unable to grant access to the newly selected shared drives. Please try again later.',
            });
          } else {
            this.toastService.success({
              summary: 'Added Successfully',
              detail:
                'Access successfully added to the newly selected shared drives.',
            });
          }
          this.resetPage();
        },
        error: () => {
          this.toastService.error({
            summary: 'Failed to Add Access',
            detail:
              'Unable to grant access to the newly selected shared drives. Please try again later.',
          });
          this.resetPage();
        },
      });
    }
  }

  getUserDrives(nextPageToken?: string) {
    this.gdrivesService
      .getUserAuthorizedDrives({
        limit: 50,
        next_page_token: nextPageToken,
      })
      .subscribe({
        next: (response) => {
          this.userDrives = [...this.userDrives, ...response.drives];
          if (response.next_page_token) {
            this.getUserDrives(response.next_page_token);
          } else {
            this.loadingDrives = false;
          }
        },
      });
  }

  saveSelectedDrives(updateClientOption: UpdateClientOptions) {
    this.updateWatchedDrives(updateClientOption);
    this.updateClient(
      this.client?.service_configuration?.custom_drive_watch,
      updateClientOption.automaticallyWatchNewDrives,
      updateClientOption.isConfidentialityEnabled
    );
  }

  toggleDisplaySwitch() {
    if (!this.hasRights) {
      this.toastService.clear();
      return this.toastService.error({
        summary: this.wording.errors['noRightsToast'].summary,
        detail: this.wording.errors['noRightsToast'].detail,
      });
    }
    if (this.client?.service_configuration !== undefined) {
      if (this.client.service_configuration?.custom_drive_watch) {
        this.displayConfirmationDialog = true;
      } else {
        this.client.service_configuration.custom_drive_watch =
          !this.client.service_configuration.custom_drive_watch;
      }
    }
  }

  desactivateCustomInstallation() {
    if (this.client?.service_configuration !== undefined) {
      this.client.service_configuration.custom_drive_watch =
        !this.client.service_configuration.custom_drive_watch;
      this.displayConfirmationDialog = false;
      this.updateClient(
        this.client.service_configuration.custom_drive_watch,
        this.client.service_configuration
          .watch_all_new_drives_created_after_date,
      );
      this.watchAllDrives();
    }
  }
}
