import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  DOC_TYPES,
  CONFIDENTIALITIES,
  LANGUAGES,
  Tag,
  AngularComponentInputs,
  CustomLabel,
  LabelOption,
  FileLabel,
  People,
  DEFAULT_GOOGLE_AVATAR_URI,
} from 'app/types';
import { DialogState } from 'app/pages/side-panel/side-panel.types';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { lastValueFrom, map } from 'rxjs';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { PeopleService } from 'app/services/people/people.service';

@Component({
  selector: 'app-properties-dialog',
  templateUrl: './properties-dialog.component.html',
  styleUrl: './properties-dialog.component.scss',
})
export class PropertiesDialogComponent {
  @Output() closeDialog = new EventEmitter();

  readonly newTagId: number = -1;
  readonly confidentialityOptions = CONFIDENTIALITIES;
  readonly DialogState = DialogState;
  readonly default_avatar_uri: string = DEFAULT_GOOGLE_AVATAR_URI;

  cancelPropertiesDialog: Function = () => {};
  savePropertiesDialog: Function = () => {};


  tagOptions: Tag[] = [];
  searchedTags: Tag[] = [];
  tagsInput: Tag[] = [];
  confidentialitiesInput?: string = undefined;
  documentTypeInput?: string = undefined;
  languageInput?: string = undefined;
  searchedCustomLabels: CustomLabel[] = [];
  customLabels: CustomLabel[] = [];
  selectedCustomLabelsOptions: LabelOption[][] = [];
  selectedCustomLabelsPeople: People[][] = [];
  peopleLabelFieldAssociatedArray: (string | undefined)[] = [];
  searchedCustomLabelsOptions: Map<string, LabelOption[]> = new Map();
  hasRight: boolean = false;
  searchedPeople: People[] = [];

  constructor(
    @Inject('INPUTS') private inputs: AngularComponentInputs,
    @Inject('OUTPUTS') public outputs: AngularComponentInputs,
    private gdriveService: GdrivesService,
    private peopleService: PeopleService,
  ) {
    this.tagOptions = inputs.tags;
    this.tagsInput = inputs.fileTags;
    this.confidentialitiesInput = this.upperCaseFirstLetter(
      inputs.fileConfidentiality,
    );
    this.documentTypeInput = this.upperCaseFirstLetter(inputs.fileType);
    this.languageInput = this.upperCaseFirstLetter(inputs.fileLanguage);
    this.cancelPropertiesDialog = outputs.cancelPropertiesDialog;
    this.savePropertiesDialog = outputs.savePropertiesDialog;
    this.customLabels = inputs.customLabels ?? [];
    for (const [index, customLabel] of this.customLabels.entries()) {
      this.selectedCustomLabelsOptions[index] = inputs.fileCustomLabels
        .filter(
          (fc: CustomLabel) => fc.label_field.id === customLabel.label_field.id,
        )
        .map((fc: FileLabel) => fc.selected_options)
        .flat();
      
        this.selectedCustomLabelsPeople[index] = inputs.fileCustomLabels
        .filter(
          (fc: CustomLabel) => fc.label_field.id === customLabel.label_field.id,
        )
        .map((fc: FileLabel) => fc.selected_people)
        .flat();
    }
    this.peopleLabelFieldAssociatedArray = this.customLabels.map((label) =>
      label.type === 'PERSON' ? label.label_field.id : undefined
    );
    this.hasRight = inputs.hasRight;
  }

  upperCaseFirstLetter(str: string) {
    if (!str) return '';
    return str?.charAt(0).toUpperCase() + str.slice(1) || '';
  }

  async searchTags(event: AutoCompleteCompleteEvent) {
    this.searchedTags = [];

    // Check if the tag name is not already in the list
    const tagExists = this.tagOptions.filter(
      (tag) => tag?.label.toLowerCase() === event?.query.toLocaleLowerCase(),
    );

    this.searchedTags = this.tagOptions.filter((tag) => {
      return tag?.label.toLowerCase().includes(event.query.toLowerCase());
    });

    if (tagExists.length === 0) {
      // Add the tag to the beginning of the list if it doesn't exist without deleting the other tags
      this.searchedTags.unshift({ id: this.newTagId, label: event.query });
    }
  }

  getSelectedCustomLabels() {
    this.selectedCustomLabelsOptions = this.selectedCustomLabelsOptions.map(
      (subArray) => subArray.filter((item) => item),
    );
    return this.selectedCustomLabelsOptions.flat();
  }

  getSelectedCustomPeople() {
    return this.selectedCustomLabelsPeople.map((people, index) => {
      const id = this.peopleLabelFieldAssociatedArray[index];
      if (id) return { id, emails: (Array.isArray(people)) ? people.map((person) => person.email) : [(people as People).email] };
      return null;
    }).filter((item) => item);
  }

  escapeRegExp(text: string): string {
    return text.replace(/[\\"]/g, '\\$&');
  }
  
  async searchPeople(event: AutoCompleteCompleteEvent) {
      const query: string = event.query;
  
      this.peopleService.searchEntities(query).subscribe((data) => {
        this.searchedPeople = data;
      });
      return this.searchedPeople;
    }
}
