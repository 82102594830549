<div class="footer">
  <div class="footer-text">{{ selectedFiles.length }} files selected</div>
  <div class="footer-buttons">
    @if (AI) {
      <p-button severity="secondary" label="Ask AI" size="small"></p-button>
    }
    @if (properties) {
      <p-button
        severity="secondary"
        label="Apply properties"
        size="small"
        (mousedown)="toggleDialogState('updateProperties', true)"
      ></p-button>
    }
  </div>
</div>

<p-dialog
  header="Apply your edits"
  [modal]="true"
  [(visible)]="dialogModalStates.updateProperties.opened"
  [style]="{ width: '30%' }"
>
  <div class="apply-properties">
    <p-autoComplete
      [(ngModel)]="selectedTags"
      [suggestions]="suggestedTags"
      optionLabel="label"
      [multiple]="true"
      (completeMethod)="searchTags($event)"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      placeholder="Select tags"
    >
      <ng-template let-tag pTemplate="item">
        @if (tag.id !== -1) {
          <div class="tag-selector">
            <i class="pi pi-tags"></i>
            <span>{{ tag.label }}</span>
          </div>
        } @else {
          <div class="tag-selector blue">
            <i class="pi pi-plus-circle"></i>
            <span>{{ tag.label }}</span>
          </div>
        }
      </ng-template>
    </p-autoComplete>
    <p-dropdown
      [options]="confidentialities"
      [(ngModel)]="selectedConfidentiality"
      optionLabel="name"
      placeholder="Confidentiality"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="docTypes"
      [(ngModel)]="selectedDocType"
      optionLabel="name"
      placeholder="Document type"
      class="dropdown-width"
    />
    <p-dropdown
      [options]="languages"
      [(ngModel)]="selectedLanguage"
      optionLabel="name"
      placeholder="Language"
      class="dropdown-width"
    />
    <diV class="properties-buttons">
      <p-button
        label="Cancel"
        severity="secondary"
        (mousedown)="toggleDialogState('updateProperties', false)"
      />
      <p-button
        label="Apply to the {{ selectedFiles.length }} files selected"
        (mousedown)="applyProperties()"
      />
    </diV>
  </div>
</p-dialog>

<p-dialog
  header="Unable to apply Properties"
  [modal]="true"
  [(visible)]="dialogModalStates.disableEditProperties.opened"
  position="center"
>
  <div class="info-text">
    Your rights regarding the following {{ entitiesNotUpdated.length }} files
    prevent you from applying properties to them:
  </div>
  <p-card class="file-card" *ngFor="let entity of entitiesNotUpdated">
    <img src="{{ entity.id.icon_link }}" alt="" class="file-icon" />
    <span>{{ entity.id.datamart?.name }}</span>
    <span class="failed-icon-info">
      <i class="pi pi-info-circle" pTooltip="{{ entity.reason }}"></i>
    </span>
  </p-card>
  <div class="dialog-one-action">
    <p-button label="I understand" (mousedown)="requestEditorAccess()" />
  </div>
</p-dialog>

<p-dialog
  header="Ask to be an editor"
  [modal]="true"
  [(visible)]="askToBeEditor"
  [style]="{ width: '40%' }"
>
  <textarea
    rows="5"
    pInputTextarea
    [(ngModel)]="reasonToBeEditor"
    class="description-textarea"
  >
  </textarea>
  <div class="dialog-actions">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="closeReasonToBeEditorDialog()"
    />
    <p-button
      label="Save"
      (mousedown)="sendReasonToBeEditor()"
      [disabled]="reasonToBeEditor === ''"
    />
  </div>
</p-dialog>
