import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { AuthorizedGoogleDrive, Client, FunctionEmitter } from 'app/types';

@Component({
  selector: 'app-shared-drive-selector',
  templateUrl: './shared-drive-selector.component.html',
  styleUrl: './shared-drive-selector.component.scss',
})
export class SharedDriveSelectorComponent implements OnChanges {
  @Output() saveChanges: EventEmitter<FunctionEmitter> = new EventEmitter();
  @Input() sharedDrives: AuthorizedGoogleDrive[] = [];
  @Input() loadingDrives: boolean = false;
  @Input() client?: Client = undefined;
  selectedUserDrives: AuthorizedGoogleDrive[] = [];
  isAutoInstallingNextDrive: boolean = true;
  isConfidentialityEnabled: boolean = false;

  ngOnChanges() {
    // Select only the drives that are watched
    this.selectedUserDrives = this.sharedDrives.filter(
      (drive) => drive.is_watched,
    );
    this.isAutoInstallingNextDrive = this.client?.service_configuration
      ?.watch_all_new_drives_created_after_date
      ? true
      : false;
    this.isConfidentialityEnabled = this.client?.service_configuration
      ?.disabled_on_secret_confidentiality
      ? true
      : false;
  }
}
