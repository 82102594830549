import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { OnboardingService } from 'app/services/onboarding/onboarding.service';
import { MenuItem } from 'primeng/api';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { ClientService } from 'app/services/client/client.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  CONTRIBUTOR_ACCESS_LINK,
  DEFAULT_EMAIL,
  INSTALL_EXTENSION_LINK,
  ITEMS,
  LOADING_ONBOARDING_STATES,
  ONBOARDING_STATE_TO_STEP,
  OnBoardingState,
  SERVICES_PRIVILEGES_LINK,
  Client,
  Role,
  GoogleDrive,
} from 'app/types';
import { ToastService } from 'app/services/toast/toast.service';
import { MultiSelect } from 'primeng/multiselect';
import { copyToClipboard, redirectTo } from 'app/utils/utils';
import { PasswordUtils } from 'app/utils/password-utils';
import { Router } from '@angular/router';

export interface Dialogs {
  createDedicatedAccount: boolean;
  connectWithTechUser: boolean;
  installOnSharedDrives: boolean;
  installExtension: boolean;
}

export type DialogsKey = keyof Dialogs;

const REFRESH_SCANNING_STATE_DELAY_SECONDS: number = 1 * 60;

@Component({
  selector: 'app-setup-onboarding',
  templateUrl: './setup-onboarding.component.html',
  styleUrl: './setup-onboarding.component.scss',
})
export class SetupOnboardingComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  displayModal: boolean = false;
  clientEmail: string = '';
  clientPassword: string = '';
  authorizedDrives: { name: string; id: string }[] = [];
  selectedAuthorizedDrives: { name: string; id: string }[] = [];
  servicesLink: string = SERVICES_PRIVILEGES_LINK;
  contributorAccessLink: string = CONTRIBUTOR_ACCESS_LINK;
  isAuthorizedDrivesLoading: boolean = false;
  client?: Client;

  items: MenuItem[] = ITEMS;
  activeIndex: number = 0;
  isLoaded: boolean = true;

  clientLabel: string = '';
  openDialogs: Dialogs = {
    createDedicatedAccount: false,
    connectWithTechUser: false,
    installOnSharedDrives: false,
    installExtension: false,
  };

  private backgroundCheckTimeoutOnDrives: NodeJS.Timeout | null = null;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isLoading: boolean = true;
  isAccountCreationInProgress: boolean = false;
  isInstallingOnSharedDrives: boolean = false;
  pwdCopied: boolean = false;
  currentOnboardingState: OnBoardingState =
    OnBoardingState.DEDICATED_ACCOUNT_CREATION;

  constructor(
    private onboardingService: OnboardingService,
    private authService: AuthService,
    private gdrivesService: GdrivesService,
    private clientService: ClientService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe({
      next: (userResponse) => {
        const user = userResponse.user;
        const roles = user.roles || [];
        this.clientLabel = user.domain.name.split('.')[0]; // Adjust based on the actual structure of the user object

        if (roles.includes(Role.GOOGLE_DOMAIN_ADMIN)) {
          // The user has the 'manager' role, proceed with the service call
          this.clientService.getClient().subscribe(
            (response) => {
              this.client = response.client;
              const state = response.client.onboarding_state;
              this.activeIndex = state ? ONBOARDING_STATE_TO_STEP[state] : 0;
              this.clientEmail =
                response.client.service_configuration?.email || '';
              this.prepareStepInformation(state);
            },
            (error) => {
              if (error.status === 404) {
                this.activeIndex = 0;
                this.toggleDialog('createDedicatedAccount');
              }
              this.isLoading = false; // Ensure to stop loading even if there's an error
            },
          );
        } else {
          this.activeIndex = 0;
          this.isLoading = false;
        }
      },
      error: () => {
        this.activeIndex = 0;
        this.isLoading = false;
      },
    });
  }

  isServiceAccountInCreation() {
    return [
      OnBoardingState.DEDICATED_ACCOUNT_LOGIN,
      OnBoardingState.DEDICATED_ACCOUNT_LICENSE_ASSIGNMENT,
      OnBoardingState.DEDICATED_ACCOUNT_PERMISSIONS_CREATION,
    ].includes(this.client!.onboarding_state!);
  }

  checkUserRoleAndRedirect() {
    this.authService.getUser().subscribe({
      next: (userResponse) => {
        const user = userResponse.user;
        const roles = user.roles || []; // Adjust based on the actual structure of the user object

        if (roles.includes(Role.GOOGLE_DOMAIN_ADMIN)) {
          this.toggleDialog('createDedicatedAccount');
        } else {
          // The user does not have the 'manager' role, redirect to Google Auth
          this.redirectToGoogleAuth();
        }
      },
      error: () => {
        this.redirectToGoogleAuth();
      },
    });
  }

  redirectToGoogleAuth(
    role: Role = Role.GOOGLE_DOMAIN_ADMIN,
    logout: boolean = true,
  ) {
    if (logout) this.authService.logout();
    const redirectUri = new URL(this.authService.redirectUri);

    // Manually set the redirect parameter to the specific URL
    redirectUri.searchParams.append('redirect', 'onboarding/start');

    this.authService
      .getAuthorizeUrl({
        forceConsent: true,
        redirectUri: redirectUri.href,
        role: role,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          window.location.href = res.authorize_url;
        },
      });
  }

  connectServiceAccount() {
    const redirectUri = new URL(this.authService.redirectUri);

    // Manually set the redirect parameter to the specific URL
    redirectUri.searchParams.append('redirect', 'onboarding/start');

    this.authService
      .getAuthorizeUrl({
        forceConsent: true,
        redirectUri: redirectUri.href,
        role: Role.GOOGLE_DOMAIN_DEDICATED_USER,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          window.location.href = res.authorize_url;
        },
      });
  }

  prepareStepInformation(state: OnBoardingState = OnBoardingState.NOT_STARTED) {
    this.isLoading = LOADING_ONBOARDING_STATES.includes(state);
    switch (this.activeIndex) {
      case 0:
        this.toggleDialog('createDedicatedAccount');
        break;

      case 1:
        this.prepareClientEmailAndPassword(this.client!);
        break;

      case 2:
        this.getDrives();
        break;

      case 4:
        this.activeIndex = 1;
        this.toggleDialog('connectWithTechUser');
        break;

      default:
        break;
    }
  }

  private prepareClientEmailAndPassword(client: Client) {
    this.clientEmail = `${DEFAULT_EMAIL}@${client.domain.name.toLowerCase()}`;
    this.clientPassword = PasswordUtils.generatePassword(10);
  }

  private deleteClientAndReset() {
    this.toggleDialog('createDedicatedAccount');
    return;
    this.clientService.deleteClient().subscribe({
      next: () => {
        this.activeIndex = 0;
        this.isLoading = false;
        this.toggleDialog('createDedicatedAccount');
      },
      error: () => {
        this.isLoading = false;
        this.toggleDialog('createDedicatedAccount'); // Ensure to stop loading even if there's an error
      },
    });
  }

  navigateToSettings() {
    this.router.navigateByUrl('/settings/installation');
  }

  installOverlayerExtension() {
    redirectTo(INSTALL_EXTENSION_LINK);
  }

  confirmDedicatedAccountNameSelection() {
    if (this.clientLabel) {
      if (
        this.client &&
        this.client.onboarding_state !== OnBoardingState.NOT_STARTED
      ) {
        this.activeIndex =
          ONBOARDING_STATE_TO_STEP[this.client!.onboarding_state!];
        return;
      }

      this.onboardingService
        .startOnboarding(this.clientLabel)
        .subscribe((response) => {
          this.client = response.client;
          this.clientService.getClient().subscribe(() => {});
          this.clientEmail = `${DEFAULT_EMAIL}@${response.client.domain.name.toLowerCase()}`;
          this.clientPassword = PasswordUtils.generatePassword(10);
          this.activeIndex =
            ONBOARDING_STATE_TO_STEP[
              OnBoardingState.DEDICATED_ACCOUNT_CREATION
            ];
          this.toggleDialog('createDedicatedAccount', false);
        });
    }
  }

  getDrives(nextPageToken?: string) {
    if (this.backgroundCheckTimeoutOnDrives) {
      clearTimeout(this.backgroundCheckTimeoutOnDrives);
    }
    this.isAuthorizedDrivesLoading = true;
    this.gdrivesService
      .getUserDrives({
        limit: 50,
        next_page_token: nextPageToken,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          data.drives.forEach((drive: GoogleDrive) => {
            this.authorizedDrives.push({
              name: `${drive.name}(${drive.id})`,
              id: drive.id,
            });
          });
          this.selectedAuthorizedDrives = [...this.authorizedDrives];
        },
        complete: () => {
          if (nextPageToken) {
            return this.getDrives(nextPageToken);
          } else {
            this.backgroundCheckTimeoutOnDrives = setTimeout(() => {
              return this.getDrives();
            }, 1000 * REFRESH_SCANNING_STATE_DELAY_SECONDS);
            this.isAuthorizedDrivesLoading = false;
          }
        },
      });
  }

  createUser() {
    if (this.pwdCopied || this.isServiceAccountInCreation()) {
      this.isAccountCreationInProgress = true;
      this.onboardingService
        .createServiceAccount(this.clientEmail, this.clientPassword)
        .subscribe({
          next: () => {
            this.getDrives();
            this.connectServiceAccount();
            this.isAccountCreationInProgress = false;
          },
          error: (error) => {
            this.toastService.error({
              detail: error.message,
            });
            this.isAccountCreationInProgress = false; // Stop loading on error
          },
        });
    } else {
      this.toastService.error({
        detail: 'You need to copy the login info (email + password)',
      });
    }
  }

  toggleDialog(dialog: DialogsKey, state?: boolean) {
    if (state !== undefined) {
      this.openDialogs[dialog] = state;
      return;
    }
    this.openDialogs[dialog] = !this.openDialogs[dialog];
  }

  clearFilter() {
    this.selectedAuthorizedDrives = [];
  }

  installOnSharedDrives() {
    this.isInstallingOnSharedDrives = true;
    const selectedIds = this.selectedAuthorizedDrives.map((drive) => drive.id);
    this.onboardingService
      .postDriveSelectionForOnboarding(selectedIds)
      .subscribe({
        next: () => {
          this.toggleDialog('installOnSharedDrives', false);
          this.isInstallingOnSharedDrives = false;
          this.activeIndex =
            ONBOARDING_STATE_TO_STEP[
              OnBoardingState.OVERLAYER_EXTENSION_INSTALLATION
            ];
        },
        error: (error) => {
          this.toastService.error({
            detail: error.message,
          });
          this.isInstallingOnSharedDrives = false;
        },
      });
  }

  copyPassword(): void {
    copyToClipboard(this.clientPassword, this.toastService);
    this.pwdCopied = true;
  }

  redirectTo(link: string) {
    redirectTo(link);
  }

  installOnAllSharedDrive() {
    this.isInstallingOnSharedDrives = true;
    const selectedIds = this.selectedAuthorizedDrives.map((drive) => drive.id);
    this.onboardingService
      .postDriveSelectionForOnboarding(selectedIds)
      .subscribe({
        next: () => {
          this.toggleDialog('installOnSharedDrives', false);
          this.isInstallingOnSharedDrives = false;
          this.activeIndex =
            ONBOARDING_STATE_TO_STEP[
              OnBoardingState.OVERLAYER_EXTENSION_INSTALLATION
            ];
        },
        error: (error) => {
          this.toastService.error({
            detail: error.message,
          });
          this.isInstallingOnSharedDrives = false;
        },
      });
  }
}
