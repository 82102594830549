<div class="select-custom-workflow">
  <p-dropdown
    [options]="$templates()"
    [loading]="loadingTemplates()"
    [(ngModel)]="workflowSelectedOption"
    optionLabel="name"
    placeholder="Workflow option"
    class="dropdown-width"
  />
  <span>
    Obtain the 'Verified' label after getting approved by a shared drive
    manager.
  </span>
  <p-button
    label="Start verification"
    [disabled]="!workflowSelectedOption"
    (onClick)="startWorkflow(workflowSelectedOption)"
  />
</div>
