<div class="shared-drive-container">
  <p-multiSelect
    placeholder="Select shared drive to add Overlayer to"
    [options]="sharedDrives"
    [loading]="loadingDrives"
    [(ngModel)]="selectedUserDrives"
    optionLabel="name"
    display="chip"
    showClear="true"
    class="multi-select"
  />
  <p-checkbox
    [(ngModel)]="isAutoInstallingNextDrive"
    [binary]="true"
    label="Install Overlayer automatically for shared drives created after today"
  />
  <h1 class="custom-shared-drives-title">
    Overlayer confidentiality settings
  </h1>
  <p-checkbox
    [(ngModel)]="isConfidentialityEnabled"
    [binary]="true"
    label="Do not activate Overlayer functionalities on files with Secret confidentiality"
  />
  <p-button
    label="Save changes"
    size="small"
    class="save-changes-button"
    raised
    (click)="
      saveChanges.emit({
        googleDrives: selectedUserDrives,
        automaticallyWatchNewDrives: isAutoInstallingNextDrive,
        isConfidentialityEnabled: isConfidentialityEnabled,
      })
    "
  />
</div>
