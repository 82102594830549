@let workflowInstances = $workflowInstances();
@let runningStep = $runningStep();
@let loading = $loading();
@let fileId = $fileId();
@let authorizations = $authorizations();

@if (loading) {
  <div class="skeleton-activity">
    <p-skeleton height="70px" class="skeleton-activity" styleClass="mb-2" />
  </div>
} @else {
  <div class="activity-container">
    <span class="span-header">Activity</span>

    @for (instance of workflowInstances; track instance.id) {
      @let state = workflowStatesMap[instance.state];
      @let stateIcon = workflowStatesSingleIconMap[instance.state];

      <p-panel
        toggleable
        [collapsed]="instance.state !== Workflow.WAITING"
        collapseIcon="pi pi-chevron-down"
        expandIcon="pi pi-chevron-up"
      >
        <ng-template pTemplate="header">
          <div class="activity-row">
            <div class="activity-row-begin">
              <div class="state-icon">
                @if (!workflowInstanceHasARejectedUserAction(instance) && instance.state === Workflow.REJECTED) {
                  <i class="pi pi-ban state-icon"></i>
                } @else {
                  <i class="{{stateIcon}} state-icon"></i>
                }
              </div>

              <div
                class="workflow-name"
                [pTooltip]="instance.template.name"
                tooltipPosition="top"
              >
                {{ instance.template.name }}
              </div>

              @if (instance.state === Workflow.VALIDATED || instance.state === Workflow.REJECTED) {
                <p-tag
                  class="worflow-status version-colors"
                  [class]="state.class"
                  [value]="getWorkflowInstanceFormatedVersion(instance)"
                />
              } @else {
                <p-tag
                  class="worflow-status version-colors"
                  [icon]="state.icon"
                  [class]="state.class"
                />
              }

              @if (instance.pdf_file_id) {
                <div
                  class="pdf-export"
                  role="button"
                  (click)="drivePreview.emit(instance.pdf_file_id)"
                >
                  <i class="pi pi-file-pdf"></i>
                </div>
              }
            </div>

            @if (instance.finished_at) {
              <div class="date" [pTooltip]="getTimeFromDate(instance.finished_at)" tooltipPosition="top">
                {{ instance.finished_at | date: 'dd/MM/yy' }}
              </div>
            }
          </div>
        </ng-template>

        <div class="timeline">
          <div class="bullet">
            <span></span>
          </div>
          <div class="content">
            <span
              class="step-name"
              [pTooltip]="getRequesterUser(instance)"
            >
              Requested
            </span>
            <div class="date" [pTooltip]="getTimeFromDate(instance.created_at)" tooltipPosition="left">
              {{ instance.created_at | date: 'dd/MM/yy' }}
            </div>
          </div>
          @for (step of instance.steps; track step.id) {
            @let stepState = workflowStepStatesMap[step.state];
            <div class="bullet">
              <span></span>
            </div>
            <div class="content">
              <div class="step-row-begin">
                <span class="step-name" [pTooltip]="getStepActionUsers(step.user_actions)" tooltipPosition="top">
                  {{ step.template.name }}
                </span>
                @if(!isStepRejected(step) && instance.state === Workflow.REJECTED) {
                  <p-tag
                    class="worflow-status"
                    [pTooltip]="stepState.value"
                    tooltipPosition="right"
                    icon="pi pi-ban"
                    [class]="stepState.class"
                  />
                } @else {
                  <p-tag
                    class="worflow-status"
                    [pTooltip]="stepState.value"
                    tooltipPosition="right"
                    [icon]="stepState.icon"
                    [class]="stepState.class"
                  />
                }
              </div>
              
              @if (step.state_modified_at) {
                <div class="date" [pTooltip]="getTimeFromDate(step.state_modified_at)" tooltipPosition="left">
                  {{ step.state_modified_at | date: 'dd/MM/yy' }}
                </div>
              }
            </div>
          }

          @if (fileId && instance.file_id && instance.file_id !== fileId) {
            <div class="bullet">
              <span></span>
            </div>
            <div class="content">
              <div class="step-row-begin">
                <div class="state-icon">
                  <i class="pi pi-eye state-icon"></i>
                </div>
                <!--p-tag class="worflow-status workflow-info" icon="pi pi-eye" /-->
                <span
                  class="step-link"
                  role="link"
                  (click)="drivePreview.emit(instance.file_id)"
                >
                  Link to original file
                </span>
              </div>
            </div>
          }
        </div>
      </p-panel>

      @if (instance.state !== Workflow.REJECTED) {
        @if (runningStep && instance.steps.includes(runningStep)) {
          <div class="actions">
            <p-button
              label="Reject"
              [outlined]="true"
              size="small"
              [disabled]="!authorizations?.can_reject"
              (onClick)="rejectWorkflowEvent.emit(runningStep.id)"
            />
            <p-button
              label="Approve"
              size="small"
              [disabled]="!authorizations?.can_approve"
              (onClick)="approveWorkflow(instance, runningStep)"
            />
          </div>
        }
      }
    }

    @if (!runningStep && $templates() > 0) {
      <p-button
        label="Start workflow"
        size="small"
        class="margin-top-button-action"
        (onClick)="startWorkflowEvent.emit()"
      />
    }
  </div>
}
